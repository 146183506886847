import React, { useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import ZaplynLogo from "../../../assets/ZaplynLogo.svg";
import { Auth } from "aws-amplify";

function ConfirmOtp() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { username, email } = state;

  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [codeResendSuccess, setCodeResendSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      await Auth.confirmSignUp(username, otp);
      navigate("/login");
    } catch (error) {
      console.log("err", error.message);
    }
    setLoading(false);
  };

  const handleResendCode = async () => {
    try {
      await Auth.resendSignUp(username);
      setCodeResendSuccess(true);
    } catch (error) {
      console.log("err", error.message);
    }
  };

  useEffect(() => {
    if (codeResendSuccess) {
      setTimeout(() => setCodeResendSuccess(false), 5000);
    }
  }, [codeResendSuccess]);

  return (
    <div className="flex bg-authBg h-screen font-lexand">
      <div className="flex flex-col justify-center items-center w-full max-w-md  mx-auto p-2">
        <div className=" border border-pink-200 bg-white p-5 w-full shadow-md rounded-md">
          <div className=" flex flex-col items-center mb-5">
            <img src={ZaplynLogo} alt="" className="w-40" />
            {/* <h4 className="text-3xl font-bold">Hello Again!</h4>
            <span className="py-4 text-xl text-center text-gray-500">
              Explore More by connecting with us.
            </span> */}
          </div>

          <form className="py-4" onSubmit={handleSubmit}>
            <div className=" flex flex-col items-center gap-5">
              {/* <div className="w-3/4">
                <p className="text-left">Username *</p>
                <input
                  className="border border-pink-100  px-5 p-2 w-full rounded-xl shadow-sm text-sm focus:outline-none"
                  type="text"
                  value={username}
                  disabled
                />
              </div> */}
              <div className=" w-3/4">
                <p className="text-left ">Verification Code *</p>
                <input
                  className="border border-pink-100  px-5 p-2 w-full rounded-xl shadow-sm text-sm focus:outline-none"
                  type="number"
                  value={otp}
                  required
                  onChange={(e) => setOtp(e.target.value)}
                  placeholder="Enter code"
                />
                <p className="text-xs bg-gradient-to-b from-purple-500 to-pink-500 to-20%  text-transparent bg-clip-text pt-2 animate-pulse">
                  Verification code sent to {email}{" "}
                </p>
              </div>
              <button
                className="rounded-lg px-4 py-1 w-3/4 mt-2 bg-gradient-to-b from-purple-200 to-pink-300 transform active:from-pink-300 active:to-purple-300 active:scale-95 text-gray-50 text-xl shadow-sm text-center"
                type="submit"
              >
                {loading ? "loading.." : "Confirm"}
              </button>
              <p
                className="rounded-lg px-4 py-1 w-3/4 mt-2 bg-gradient-to-b from-purple-200 to-pink-300 transform active:from-pink-300 active:to-purple-300 active:scale-95 text-gray-50 text-xl shadow-sm text-center"
                onClick={handleResendCode}
              >
                {codeResendSuccess ? "Code Sent" : "Resend code"}
              </p>
            </div>

            <div className="text-center py-4">
              <span className="text-gray-500">
                <Link className="" to="/login">
                  Back to sign In
                </Link>
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ConfirmOtp;
