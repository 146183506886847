import React, { useState } from "react";
import useMediaQuery from "../../lib/use-media-query";

const How_It_Works = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [closeAccordian, setCloseAccordian] = useState(false);
  const { isMobile } = useMediaQuery();

  const handleAccordianClose = () => {
    setTimeout(() => setIsOpen(false), 350);
  };

  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);
  const [isOpen6, setIsOpen6] = useState(false);
  const [isOpen7, setIsOpen7] = useState(false);
  const [isOpen8, setIsOpen8] = useState(false);
  const [isOpen9, setIsOpen9] = useState(false);
  const [isOpen10, setIsOpen10] = useState(false);
  const [isOpen11, setIsOpen11] = useState(false);
  const [isOpen12, setIsOpen12] = useState(false);
  const [isOpen13, setIsOpen13] = useState(false);
  const [isOpen14, setIsOpen14] = useState(false);
  const [isOpen15, setIsOpen15] = useState(false);
  const [isOpen16, setIsOpen16] = useState(false);
  const [isOpen17, setIsOpen17] = useState(false);
  const [isOpen18, setIsOpen18] = useState(false);
  const [isOpen19, setIsOpen19] = useState(false);
  const [isOpen20, setIsOpen20] = useState(false);

  return (
    <div className="pt-20 pb-40  ">
      <div className="flex flex-col items-start md:max-w-xl mx-auto gap-2 px-2 text-lg space-y-2 ">
        {/* top section */}
        <div className="flex flex-col items-center w-full mt-2 md:mt-0  gap-2 mb-2">
          <p className="px-2 text-sm font-medium tracking-wider text-center uppercase">
            How it works
          </p>
          <h2 className="mb-2 text-xl font-bold leading-none text-center sm:text-3xl">
            Frequently Asked Questions
          </h2>
        </div>

        {/* first */}
        <div className="w-full">
          <div className="flex items-start gap-4">
            <button onClick={() => setIsOpen1(!isOpen1)} className=" w-6 h-6">
              {isOpen1 ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="w-6 h-6"
                >
                  <path d="M5 11V13H19V11H5Z" fill="rgba(243,149,198,1)"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="rgba(243,149,198,1)"
                  ></path>
                </svg>
              )}
            </button>
            <h3
              className={`  max-w-3/4 ${
                isOpen1 ? "text-gray-900" : "text-gray-500"
              }`}
            >
              What is a Zaplyn link?
            </h3>
          </div>

          {isOpen1 && (
            <div className="flex flex-col items-start gap-3 w-full">
              <div className="flex flex-col items-center  w-full h-full p-3 md:p-4">
                <iframe
                  width={isMobile ? "300" : "530"}
                  height={isMobile ? "180" : "300"}
                  src="https://www.youtube.com/embed/7u89-0_Sgws?si=dQYgJAvVf_0lNpn8"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; 
                  autoplay; 
                  clipboard-write; 
                  encrypted-media; 
                  gyroscope; 
                  picture-in-picture; 
                  web-share"
                  allowfullscreen
                />
              </div>
            </div>
          )}
        </div>

        {/* second */}
        <div className="w-full">
          <div className="flex items-start gap-4">
            <button onClick={() => setIsOpen2(!isOpen2)} className=" w-6 h-6">
              {isOpen2 ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M5 11V13H19V11H5Z" fill="rgba(243,149,198,1)"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="rgba(243,149,198,1)"
                  ></path>
                </svg>
              )}
            </button>
            <h3 className={`${isOpen2 ? "text-gray-900" : "text-gray-500"}`}>
              Why should I use Zaplyn?
            </h3>
          </div>
          {isOpen2 && (
            <div className="flex flex-col items-start gap-3 w-full">
              <div className="flex flex-col items-center  w-full h-full p-3 md:p-4">
                <iframe
                  width={isMobile ? "300" : "530"}
                  height={isMobile ? "180" : "300"}
                  src="https://www.youtube.com/embed/5gZgbr3Lhnc?si=OU6gdcic3bcMFA4O"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; 
                  autoplay; 
                  clipboard-write; 
                  encrypted-media; 
                  gyroscope; 
                  picture-in-picture; 
                  web-share"
                  allowfullscreen
                />
              </div>
            </div>
          )}
        </div>

        {/* third */}
        <div className="w-full">
          <div className="flex items-start gap-4">
            <button onClick={() => setIsOpen3(!isOpen3)} className=" w-6 h-6">
              {isOpen3 ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M5 11V13H19V11H5Z" fill="rgba(243,149,198,1)"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="rgba(243,149,198,1)"
                  ></path>
                </svg>
              )}
            </button>
            <h3 className={`${isOpen3 ? "text-gray-900" : "text-gray-500"}`}>
              How do I create a product?
            </h3>
          </div>

          {isOpen3 && (
            <div className="flex flex-col items-start gap-3 w-full">
              <div className="flex flex-col items-center  w-full h-full p-3 md:p-4">
                <iframe
                  width={isMobile ? "300" : "530"}
                  height={isMobile ? "180" : "300"}
                  src="https://www.youtube.com/embed/k5lCsH5QN7s?si=7s6oVf9nVJW8GqVx"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; 
                  autoplay; 
                  clipboard-write; 
                  encrypted-media; 
                  gyroscope; 
                  picture-in-picture; 
                  web-share"
                  allowfullscreen
                />
              </div>
            </div>
          )}
        </div>

        {/* fourth */}
        <div className="w-full">
          <div className="flex items-start gap-4">
            <button onClick={() => setIsOpen4(!isOpen4)} className=" w-6 h-6">
              {isOpen4 ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M5 11V13H19V11H5Z" fill="rgba(243,149,198,1)"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="rgba(243,149,198,1)"
                  ></path>
                </svg>
              )}
            </button>
            <h3 className={`${isOpen4 ? "text-gray-900" : "text-gray-500"}`}>
              How do I create a shop?
            </h3>
          </div>
          {isOpen4 && (
            <div className="flex flex-col items-start gap-3 w-full">
              <div className="flex flex-col items-center  w-full h-full p-3 md:p-4">
                <iframe
                  width={isMobile ? "300" : "530"}
                  height={isMobile ? "180" : "300"}
                  src="https://www.youtube.com/embed/W5Oe-ha-MPE?si=nqmdtWh9IdnmqopS"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; 
                    autoplay; 
                    clipboard-write; 
                    encrypted-media; 
                    gyroscope; 
                    picture-in-picture; 
                    web-share"
                  allowfullscreen
                />
              </div>
            </div>
          )}
        </div>
        {/* fifth */}

        <div className="w-full">
          <div className="flex items-center  gap-4">
            <button onClick={() => setIsOpen5(!isOpen5)} className=" w-6 h-6">
              {isOpen5 ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M5 11V13H19V11H5Z" fill="rgba(243,149,198,1)"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="rgba(243,149,198,1)"
                  ></path>
                </svg>
              )}
            </button>
            <h3 className={`${isOpen5 ? "text-gray-900" : "text-gray-500"}`}>
              Can I create unlimited products and shops?
            </h3>
          </div>
          {isOpen5 && (
            <div className="flex flex-col items-start gap-3 w-full">
              <div className="flex flex-col items-center  w-full h-full p-3 md:p-4">
                <iframe
                  width={isMobile ? "300" : "530"}
                  height={isMobile ? "180" : "300"}
                  src="https://www.youtube.com/embed/Jk7iCGo9snc?si=OE3-3YVOg_RUfWBd"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; 
              autoplay; 
              clipboard-write; 
              encrypted-media; 
              gyroscope; 
              picture-in-picture; 
              web-share"
                  allowfullscreen
                />
              </div>
            </div>
          )}
        </div>

        {/* sixth */}
        <div className="w-full">
          <div className="flex items-center  gap-4  ">
            <button onClick={() => setIsOpen6(!isOpen6)} className=" w-6 h-6">
              {isOpen6 ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="w-6 h-6"
                >
                  <path d="M5 11V13H19V11H5Z" fill="rgba(243,149,198,1)"></path>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="w-6 h-6"
                >
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="rgba(243,149,198,1)"
                  ></path>
                </svg>
              )}
            </button>
            <h3 className={`  ${isOpen6 ? "text-gray-900" : "text-gray-500"}`}>
              What are 'Categories' and why do I need it?
            </h3>
          </div>
          {isOpen6 && (
            <div className="flex flex-col items-start gap-3 w-full">
              <div className="flex flex-col items-center  w-full h-full p-3 md:p-4">
                <iframe
                  width={isMobile ? "300" : "530"}
                  height={isMobile ? "180" : "300"}
                  src="https://www.youtube.com/embed/PoZww6oOT9s?si=-5nypyMVvqhU_5B7"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; 
                 autoplay; 
                 clipboard-write; 
                 encrypted-media; 
                 gyroscope; 
                 picture-in-picture; 
                 web-share"
                  allowfullscreen
                />
              </div>
            </div>
          )}
        </div>

        {/* seventh */}
        <div className="w-full">
          <div className="flex items-start gap-4">
            <button onClick={() => setIsOpen7(!isOpen7)} className=" w-6 h-6">
              {isOpen7 ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M5 11V13H19V11H5Z" fill="rgba(243,149,198,1)"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="rgba(243,149,198,1)"
                  ></path>
                </svg>
              )}
            </button>
            <h3 className={`${isOpen7 ? "text-gray-900" : "text-gray-500"}`}>
              What is the wallet section?
            </h3>
          </div>
          {isOpen7 && (
            <div className="flex flex-col items-start gap-3 w-full">
              <div className="flex flex-col items-center  w-full h-full p-3 md:p-4">
                <iframe
                  width={isMobile ? "300" : "530"}
                  height={isMobile ? "180" : "300"}
                  src="https://www.youtube.com/embed/uiP2Z2TetI0?si=pYwe2cuMd-c7Uvu6"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; 
                autoplay; 
                clipboard-write; 
                encrypted-media; 
                gyroscope; 
                picture-in-picture; 
                web-share"
                  allowfullscreen
                />
              </div>
            </div>
          )}
        </div>

        {/* eighth */}
        <div className="w-full">
          <div className="flex items-start gap-4">
            <button onClick={() => setIsOpen8(!isOpen8)} className=" w-6 h-6">
              {isOpen8 ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M5 11V13H19V11H5Z" fill="rgba(243,149,198,1)"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="rgba(243,149,198,1)"
                  ></path>
                </svg>
              )}
            </button>
            <h3 className={`${isOpen8 ? "text-gray-900" : "text-gray-500"}`}>
              How do I share the Zaplyn link for my shop?
            </h3>
          </div>
          {isOpen8 && (
            <div className="flex flex-col items-start gap-3 w-full">
              <div className="flex flex-col items-center  w-full h-full p-3 md:p-4">
                <iframe
                  width={isMobile ? "300" : "530"}
                  height={isMobile ? "180" : "300"}
                  src="https://www.youtube.com/embed/APrj194f16k?si=jnQiYcMInd8rcMzm"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; 
      autoplay; 
      clipboard-write; 
      encrypted-media; 
      gyroscope; 
      picture-in-picture; 
      web-share"
                  allowfullscreen
                />
              </div>
            </div>
          )}
        </div>

        {/* ninth */}
        <div className="w-full">
          <div className="flex items-start gap-4">
            <button onClick={() => setIsOpen9(!isOpen9)} className=" w-6 h-6">
              {isOpen9 ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M5 11V13H19V11H5Z" fill="rgba(243,149,198,1)"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="rgba(243,149,198,1)"
                  ></path>
                </svg>
              )}
            </button>
            <h3 className={`${isOpen9 ? "text-gray-900" : "text-gray-500"}`}>
              Do products also have Zaplyn links?
            </h3>
          </div>
          {isOpen9 && (
            <div className="flex flex-col items-start gap-3 w-full">
              <div className="flex flex-col items-center  w-full h-full p-3 md:p-4">
                <iframe
                  width={isMobile ? "300" : "530"}
                  height={isMobile ? "180" : "300"}
                  src="https://www.youtube.com/embed/2de24fPsOeo?si=rkgccviej78cWOnj"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; 
                autoplay; 
                clipboard-write; 
                encrypted-media; 
                gyroscope; 
                picture-in-picture; 
                web-share"
                  allowfullscreen
                />
              </div>
            </div>
          )}
        </div>

        {/* tenth */}
        <div className="w-full">
          <div className="flex items-start gap-4">
            <button onClick={() => setIsOpen10(!isOpen10)} className=" w-6 h-6">
              {isOpen10 ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M5 11V13H19V11H5Z" fill="rgba(243,149,198,1)"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="rgba(243,149,198,1)"
                  ></path>
                </svg>
              )}
            </button>
            <h3 className={`${isOpen10 ? "text-gray-900" : "text-gray-500"}`}>
              What are 'credits'?
            </h3>
          </div>
          {isOpen10 && (
            <div className="flex flex-col items-start gap-3 w-full">
              <div className="flex flex-col items-center  w-full h-full p-3 md:p-4">
                <iframe
                  width={isMobile ? "300" : "530"}
                  height={isMobile ? "180" : "300"}
                  src="https://www.youtube.com/embed/msDI9m_BVJs?si=Hhj5OyPEOkkQ8CEB"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; 
              autoplay; 
              clipboard-write; 
              encrypted-media; 
              gyroscope; 
              picture-in-picture; 
              web-share"
                  allowfullscreen
                />
              </div>
            </div>
          )}
        </div>
        {/* eleventh  */}
        <div className="w-full">
          <div className="flex items-start gap-4">
            <button onClick={() => setIsOpen11(!isOpen11)} className=" w-6 h-6">
              {isOpen11 ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M5 11V13H19V11H5Z" fill="rgba(243,149,198,1)"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="rgba(243,149,198,1)"
                  ></path>
                </svg>
              )}
            </button>
            <h3 className={`${isOpen11 ? "text-gray-900" : "text-gray-500"}`}>
              How do I cash out?
            </h3>
          </div>
          {isOpen11 && (
            <div className="flex flex-col items-start gap-3 w-full">
              <div className="flex flex-col items-center  w-full h-full p-3 md:p-4">
                <iframe
                  width={isMobile ? "300" : "530"}
                  height={isMobile ? "180" : "300"}
                  src="https://www.youtube.com/embed/oJz9RjU0Qf0?si=nsX1gZjPZX7EDsfO"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; 
           autoplay; 
           clipboard-write; 
           encrypted-media; 
           gyroscope; 
           picture-in-picture; 
           web-share"
                  allowfullscreen
                />
              </div>
            </div>
          )}
        </div>

        {/* 12th */}
        <div className="w-full">
          <div className="flex items-start gap-4">
            <button onClick={() => setIsOpen12(!isOpen12)} className=" w-6 h-6">
              {isOpen12 ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M5 11V13H19V11H5Z" fill="rgba(243,149,198,1)"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="rgba(243,149,198,1)"
                  ></path>
                </svg>
              )}
            </button>
            <h3 className={`${isOpen12 ? "text-gray-900" : "text-gray-500"}`}>
              Why do I need to add a payment method in my wallet?
            </h3>
          </div>
          {isOpen12 && (
            <div className="flex flex-col items-start gap-3 w-full">
              <div className="flex flex-col items-center  w-full h-full p-3 md:p-4">
                <iframe
                  width={isMobile ? "300" : "530"}
                  height={isMobile ? "180" : "300"}
                  src="https://www.youtube.com/embed/MHICcQzyEDc?si=2J_F-PrIPMOl6QM1"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; 
             autoplay; 
             clipboard-write; 
             encrypted-media; 
             gyroscope; 
             picture-in-picture; 
             web-share"
                  allowfullscreen
                />
              </div>
            </div>
          )}
        </div>

        {/* 13th */}

        {/* 14th */}

        {/* 15th */}
        <div className="w-full">
          <div className="flex items-start gap-4">
            <button onClick={() => setIsOpen15(!isOpen15)} className=" w-6 h-6">
              {isOpen15 ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M5 11V13H19V11H5Z" fill="rgba(243,149,198,1)"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="rgba(243,149,198,1)"
                  ></path>
                </svg>
              )}
            </button>
            <h3 className={`${isOpen15 ? "text-gray-900" : "text-gray-500"}`}>
              How do I find latest orders that I received?
            </h3>
          </div>
          {isOpen15 && (
            <div className="flex flex-col items-start gap-3 w-full">
              <div className="flex flex-col items-center  w-full h-full p-3 md:p-4">
                <iframe
                  width={isMobile ? "300" : "530"}
                  height={isMobile ? "180" : "300"}
                  src="https://www.youtube.com/embed/HKcD_4mz3tc?si=KYzQMTSCE-zpctan"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; 
       autoplay; 
       clipboard-write; 
       encrypted-media; 
       gyroscope; 
       picture-in-picture; 
       web-share"
                  allowfullscreen
                />
              </div>
            </div>
          )}
        </div>
        {/* 16th */}
        <div className="w-full">
          <div className="flex items-start gap-4">
            <button onClick={() => setIsOpen16(!isOpen16)} className=" w-6 h-6">
              {isOpen16 ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M5 11V13H19V11H5Z" fill="rgba(243,149,198,1)"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="rgba(243,149,198,1)"
                  ></path>
                </svg>
              )}
            </button>
            <h3 className={`${isOpen16 ? "text-gray-900" : "text-gray-500"}`}>
              Can I disable my shop or product?
            </h3>
          </div>
          {isOpen16 && (
            <div className="flex flex-col items-start gap-3 w-full">
              <div className="flex flex-col items-center  w-full h-full p-3 md:p-4">
                <iframe
                  width={isMobile ? "300" : "530"}
                  height={isMobile ? "180" : "300"}
                  src="https://www.youtube.com/embed/p71cN-ESkhQ?si=jWNr9ndJ5li9jK9a"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; 
            autoplay; 
            clipboard-write; 
            encrypted-media; 
            gyroscope; 
            picture-in-picture; 
            web-share"
                  allowfullscreen
                />
              </div>
            </div>
          )}
        </div>

        {/* 17th */}

        <div className="w-full">
          <div className="flex items-start gap-4">
            <button onClick={() => setIsOpen17(!isOpen17)} className=" w-6 h-6">
              {isOpen17 ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M5 11V13H19V11H5Z" fill="rgba(243,149,198,1)"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="rgba(243,149,198,1)"
                  ></path>
                </svg>
              )}
            </button>
            <h3 className={`${isOpen17 ? "text-gray-900" : "text-gray-500"}`}>
              Can I change theme of my shop?
            </h3>
          </div>
          {isOpen17 && (
            <div className="flex flex-col items-start gap-3 w-full">
              <div className="flex flex-col items-center  w-full h-full p-3 md:p-4">
                <iframe
                  width={isMobile ? "300" : "530"}
                  height={isMobile ? "180" : "300"}
                  src="https://www.youtube.com/embed/oBfvS-l7Fww?si=_mE-kDqpHhUyBjU5"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; 
           autoplay; 
           clipboard-write; 
           encrypted-media; 
           gyroscope; 
           picture-in-picture; 
           web-share"
                  allowfullscreen
                />
              </div>
            </div>
          )}
        </div>

        {/* 18th */}
        <div className="w-full">
          <div className="flex items-start gap-4">
            <button onClick={() => setIsOpen18(!isOpen18)} className=" w-6 h-6">
              {isOpen18 ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M5 11V13H19V11H5Z" fill="rgba(243,149,198,1)"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="rgba(243,149,198,1)"
                  ></path>
                </svg>
              )}
            </button>
            <h3 className={`${isOpen18 ? "text-gray-900" : "text-gray-500"}`}>
              I need help in creating my shop. What can I do?
            </h3>
          </div>
          {isOpen18 && (
            <div className="flex flex-wrap p-3 md:p-4">
              For any assistance, you can write to us here:{" "}
              <a
                href="https://www.zaplyn.link/support"
                className="cursor-pointer text-blue-500"
                rel="noreferrer"
                target="_blank"
              >
                https://www.zaplyn.link/support
              </a>
              . We will be happy to hear from you!
            </div>
          )}
        </div>

        {/* 19th */}

        {/* 20th */}

        <div className="w-full">
          <div className="flex items-start gap-4">
            <button onClick={() => setIsOpen20(!isOpen20)} className=" w-6 h-6">
              {isOpen20 ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M5 11V13H19V11H5Z" fill="rgba(243,149,198,1)"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="rgba(243,149,198,1)"
                  ></path>
                </svg>
              )}
            </button>
            <h3 className={`${isOpen20 ? "text-gray-900" : "text-gray-500"}`}>
              Do you have a mobile app?
            </h3>
          </div>
          {isOpen20 && (
            <div className="flex flex-wrap p-3 md:p-4">
              We don't have a moble app yet. But we will be keen to hear from
              you regarding your requirements. Please write to us here:
              <a
                href="https://www.zaplyn.link/support"
                className="cursor-pointer text-blue-500"
                rel="noreferrer"
                target="_blank"
              >
                https://www.zaplyn.link/support
              </a>
              .
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default How_It_Works;
