import React, { useContext, useEffect, useMemo, useState } from "react";
import Modal from "react-modal";

import { Carousel } from "react-responsive-carousel";
import "./ProductTile.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ShopCheckoutForm from "./ShopCheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { convertCurrency } from "../../../helper/helper";
import { ThemeContext } from "../../ThemeContext";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import { MobileDatePicker } from "@mui/x-date-pickers";
import ShopZerocheckoutForm from "./shopZeroCheckoutForm";
import CustomerNote from "../NoteModal";

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);

function Cart({
  cartData,
  backToShop,
  onRemoveFromCart,
  onDecrease,
  onIncrease,
  shopDetails,
}) {
  const [isCheckoutClicked, setIsCheckoutClicked] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [cs, setCs] = useState("");

  // theme
  const {
    theme,
    setTheme,
    themBtn,
    setThemBtn,
    themBtnStyle,
    themeTileBorder,
    setThemeTileBorder,
    themeTileBorderStyle,
    setThemeTileBorderStyle,
    themeFont,
    setThemeFont,
  } = useContext(ThemeContext);
  const options = {
    clientSecret: cs,

    appearance: {
      /*...*/
    },
  };

  // payment percentage
  const [paymentPercentage, setPaymentPercentage] = useState(
    shopDetails.partial
  );
  const [newTotalPrice, setNewTotalPrice] = useState();

  const [totalprice, setTotalPrice] = useState();

  const [scheduleDelivery, setScheduleDelivery] = useState(null);
  const [activePeriod, setActivePeriod] = React.useState(dayjs(Date.now()));

  //variants
  const [showVariant, setShowVariant] = useState(false);
  const [variants, setVariants] = useState(null);
  const [variantPrice, setVariantPrice] = useState("");

  const [selectedSlot, setSelectedSlot] = useState("{}");
  const handleSlotSelect = (e) => {
    setSelectedSlot(e.target.value);
  };

  const [slotErr, setSlotErr] = useState("");

  const [invalidSlot, setInvalidSlot] = useState([]);

  const [customerNote, setCustomerNote] = useState("");

  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);

  const setNoteModalisOpenToFalse = () => {
    setIsNoteModalOpen(false);
  };

  const handleDeleteProduct = (id) => {
    onRemoveFromCart(id);
    const updatedCart = cartData.filter((item) => item.id !== id);
    const updatedTotalPrice = updatedCart.reduce(
      (a, c) => a + c.newtotal * c.quantity,
      0
    );
    setTotalPrice(updatedTotalPrice.toFixed(1));
  };

  const handleDecreaseQuantity = (id) => {
    onDecrease(id);
    const updatedCart = cartData.map((item) =>
      item.id === id
        ? {
            ...item,
            quantity: item.quantity - 1,
          }
        : item
    );
    const updatedTotalPrice = updatedCart.reduce(
      (a, c) => a + c.newtotal * c.quantity,
      0
    );
    setTotalPrice(updatedTotalPrice.toFixed(1));
  };

  const handleIncreaseQuantity = (id) => {
    onIncrease(id);
    const updatedCart = cartData.map((item) =>
      item.id === id
        ? {
            ...item,
            quantity: parseInt(item.quantity) + 1,
          }
        : item
    );
    const updatedTotalPrice = updatedCart.reduce(
      (a, c) => a + c.newtotal * c.quantity,
      0
    );
    setTotalPrice(updatedTotalPrice.toFixed(1));
  };

  const transformedData = useMemo(() => {
    const newResult = {};

    cartData.forEach((obj) => {
      Object.keys(obj).forEach((key) => {
        if (!newResult[key]) {
          newResult[key] = "";
        }
        if (typeof obj[key] === "object") {
          newResult[key] += JSON.stringify(obj[key]) + "-";
        } else {
          newResult[key] += obj[key] + ",";
        }
      });
    });

    Object.keys(newResult).forEach((key) => {
      newResult[key] = newResult[key].slice(0, -1);
    });

    return newResult;
  }, [cartData]);

  // checkout
  const handlePurchase = () => {
    if (shopDetails.enable_scheduling) {
      if (selectedSlot !== "{}") {
        proceedToPurchase();
      } else {
        setSlotErr("Please select!");
      }
    } else {
      proceedToPurchase();
    }
  };

  const proceedToPurchase = async () => {
    setIsCheckoutClicked(true);
    setIsLoading(true);
    if (paymentPercentage === 0) {
      setCs("zero");
    } else if (paymentPercentage === 100) {
      try {
        var addonprice = cartData
          .filter((item) => item.add_ons !== null)
          .map((item) =>
            Object.values(item.addon[item.ZaplynTileRef]).reduce(
              (acc, value) => {
                return acc + parseInt(value);
              },
              0
            )
          );
        if (isNaN(parseInt(addonprice))) {
          addonprice = 0;
        }

        const response = await fetch(
          `https://omnistack.org${process.env.REACT_APP_LINK}` +
            "create-payment-intent/",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              qty: transformedData.quantity,
              ZaplynTileRef: transformedData.ZaplynTileRef,
              totalprice: (
                parseInt(totalprice) +
                parseInt(addonprice) -
                (parseInt(totalprice) + parseInt(addonprice)) *
                  (shopDetails.discount / 100)
              ).toFixed(1),
            }),
          }
        );
        const json = await response.json();
        if (json) {
          setCs(json);
        }
      } catch (error) {
        console.log("err", error);
      }
    } else {
      try {
        const response = await fetch(
          `https://omnistack.org${process.env.REACT_APP_LINK}` +
            "partial-payment-intent/",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              totalprice: newTotalPrice,
            }),
          }
        );

        const json = await response.json();
        if (json) {
          setCs(json);
        }
      } catch (error) {
        console.log("Err", error);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const itemPrice = cartData.reduce((a, c) => a + c.newtotal * c.quantity, 0);
    setTotalPrice(itemPrice.toFixed(1));
  }, [cartData]);

  useEffect(() => {
    if (selectedSlot !== "{}") {
      setScheduleDelivery({
        date: activePeriod,
        slot: JSON.parse(selectedSlot),
      });
    }
  }, [activePeriod, selectedSlot]);

  useEffect(() => {
    var addonprice = cartData
      .filter((item) => item.add_ons !== null)
      .map((item) => item.addonPrice)
      .reduce((acc, curr) => acc + curr, 0);

    // console.log(addonprice);
    if (isNaN(parseInt(addonprice))) {
      addonprice = 0;
    }
    // console.log(addonprice);
    // console.log(totalprice);

    if (paymentPercentage === 0) {
      setNewTotalPrice(0);
    } else if (paymentPercentage === 25) {
      setNewTotalPrice(
        (
          (totalprice +
            addonprice -
            (totalprice + addonprice) * (shopDetails.discount / 100)) *
          (shopDetails.partial / 100)
        ).toFixed(1)
      );
    } else if (paymentPercentage === 50) {
      setNewTotalPrice(
        (
          (totalprice +
            addonprice -
            (totalprice + addonprice) * (shopDetails.discount / 100)) *
          (shopDetails.partial / 100)
        ).toFixed(1)
      );
    }
  }, [paymentPercentage, totalprice, cartData]);

  useEffect(() => {
    if (
      !shopDetails?.multiple_bookings &&
      shopDetails?.booked_slots?.length > 0 &&
      shopDetails?.booked_slots?.map(
        (item) =>
          dayjs(item?.scheduled_time?.date).format("DD/MM/YYYY") ===
          activePeriod.format("DD/MM/YYYY")
      )
    ) {
      const newslot = shopDetails?.booked_slots
        ?.filter(
          (item) =>
            dayjs(item?.scheduled_time?.date).format("DD/MM/YYYY") ===
            activePeriod.format("DD/MM/YYYY")
        )
        .map((item) => item?.scheduled_time?.slot?.slotNo);
      setInvalidSlot(newslot);
    }
  }, [activePeriod]);

  // console.log(invalidSlot);
  // console.log(scheduleDelivery);

  // console.log("cart", cartData);
  // console.log("forme", transformedData);
  // console.log(dayjs(activePeriod).isBefore(dayjs(), "day"));

  // console.log(totalprice);
  // console.log(newTotalPrice);
  // console.log(paymentPercentage);
  // console.log(activePeriod, "new", JSON.parse(selectedSlot));
  return (
    <section>
      {isCheckoutClicked ? (
        <>
          <div className=" flex items-center justify-end mt-10">
            <button
              onClick={() => setIsCheckoutClicked(false)}
              className="text-gray-500 font-semibold cursor-pointer"
            >
              back to cart
            </button>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className="w-5 h-5 ml-1"
            >
              <path
                d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
                fill="rgba(173,184,194,1)"
              ></path>
            </svg>
          </div>
          {isLoading && <p>Loading ..</p>}
          {cs !== "" && cs !== "zero" && (
            <Elements stripe={stripePromise} options={options}>
              <ShopCheckoutForm
                orderData={transformedData}
                shopDetails={shopDetails}
                schedule_delivery={scheduleDelivery}
                partial={paymentPercentage}
                customerNote={customerNote}
              />
            </Elements>
          )}
          {cs === "zero" && paymentPercentage === 0 && (
            <ShopZerocheckoutForm
              orderData={transformedData}
              shopDetails={shopDetails}
              schedule_delivery={scheduleDelivery}
              partial={paymentPercentage}
              customerNote={customerNote}
            />
          )}
        </>
      ) : (
        <div className="">
          <div className="flex flex-col max-w-3xl p-2 space-y-4 sm:p-10 ">
            {cartData?.length > 0 ? (
              <>
                <h2 className="text-xl font-semibold">Your cart</h2>
                <ul
                  className={` flex flex-col divide-y gap-4 divide-gray-100 `}
                >
                  {cartData.map((item) => (
                    <li
                      className={`
                      ${
                        themeTileBorder === "none"
                          ? ``
                          : themeTileBorder === "light"
                          ? ` ${theme}  ${themeTileBorderStyle} shadow-sm p-0.25`
                          : ` ${theme}  ${themeTileBorderStyle} shadow-sm p-0.5`
                      }
                      flex flex-col sm:flex-row sm:justify-between  `}
                    >
                      <div
                        className={`bg-white flex flex-col w-full space-x-2 ${themeTileBorderStyle} py-3 sm:space-x-4  p-1`}
                      >
                        {/* product image */}
                        <div className={` flex p-0.5 rounded-md `}>
                          <div className="w-16 md:w-40">
                            <Carousel
                              infiniteLoop={true}
                              showStatus={false}
                              showArrows={false}
                              showIndicators={false}
                              swipeable={false}
                              // thumbWidth={30}
                              showThumbs={false}
                              className="shopProductCarousel"
                            >
                              {item.images.map((image) => (
                                <div key={image}>
                                  <img
                                    src={image}
                                    alt=""
                                    className="  w-12 h-12  sm:w-32 sm:h-32 object-contain"
                                  />
                                </div>
                              ))}
                            </Carousel>
                          </div>

                          <div className="flex justify-between w-full pb-2 space-x-2 pl-5">
                            <div className="space-y-1">
                              <h3 className="text-lg font-semibold leading-snug sm:pr-8">
                                {item.itemname}
                              </h3>
                              <p className="text-xs w-40 md:w-52 text-justify pb-2 truncate ">
                                {item.description}
                              </p>
                            </div>
                            <div className="text-right ">
                              {item?.show_variant ? (
                                <p className="text-lg font-semibold">
                                  {(
                                    parseInt(item.variant[item.variantName]) *
                                    item.quantity
                                  ).toFixed(1)}
                                  {shopDetails.currency === "GBP" && (
                                    <span className="mr-1">£</span>
                                  )}
                                  {shopDetails.currency === "EUR" && (
                                    <span className="mr-1">€</span>
                                  )}
                                  {shopDetails.currency === "USD" && (
                                    <span className="mr-1">$</span>
                                  )}
                                </p>
                              ) : (
                                <p className="text-lg font-semibold">
                                  {(item.retail_price * item.quantity).toFixed(
                                    1
                                  )}
                                  {shopDetails.currency === "GBP" && (
                                    <span className="ml-0.5">£</span>
                                  )}
                                  {shopDetails.currency === "EUR" && (
                                    <span className="ml-0.5">€</span>
                                  )}
                                  {shopDetails.currency === "USD" && (
                                    <span className="ml-0.5">$</span>
                                  )}
                                </p>
                              )}

                              {shopDetails.shipping_enabled &&
                                item.shipping_price !== 0 && (
                                  <p className="text-xs tracking-tight ">
                                    Shipping Price:{" "}
                                    <span>
                                      {(
                                        item.shipping_price * item.quantity
                                      ).toFixed(1)}
                                      {shopDetails.currency === "GBP" && (
                                        <span className="ml-0.5">£</span>
                                      )}
                                      {shopDetails.currency === "EUR" && (
                                        <span className="ml-0.5">€</span>
                                      )}
                                      {shopDetails.currency === "USD" && (
                                        <span className="ml-0.5">$</span>
                                      )}
                                    </span>
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col pb-4 gap-2">
                          {item.etm_date !== null &&
                            item.etm_date.split(",")[2] !== "" &&
                            item.etm_date.split(",")[2] !== "select" && (
                              <div className={` text-xs mt-1 `}>
                                <p className="flex gap-2  items-center justify-end ">
                                  Estimated Delivery:{" "}
                                  <span className=" ">
                                    {item.etm_date.split(",")[0]}
                                  </span>
                                  -
                                  <span className=" ">
                                    {item.etm_date.split(",")[1]}
                                  </span>
                                  <span className=" ">
                                    {item.etm_date.split(",")[2]}
                                  </span>
                                </p>
                              </div>
                            )}
                          <div className="flex w-full ">
                            <div className="flex space-x-2 text-sm divide-x divide-gray-200 w-60 md:w-full overflow-x-auto">
                              {item.addon !== null &&
                                item.addon !== undefined &&
                                Object.keys(item?.addon[item.ZaplynTileRef])
                                  .length > 0 &&
                                Object.keys(
                                  item?.addon[item.ZaplynTileRef]
                                ).map((addonName) => (
                                  <div className="ml-2 text-xs">
                                    <div className="pl-2">
                                      <p className="text-xs capitalize ">
                                        {addonName}
                                      </p>
                                      <p className=" text-center">
                                        £{" "}
                                        {
                                          item?.addon[item.ZaplynTileRef][
                                            addonName
                                          ]
                                        }
                                      </p>
                                    </div>
                                  </div>
                                ))}
                              {item?.newatrribute_1.split("-")[0] === "color" &&
                                item?.newatrribute_1.split("-")[1] !==
                                  "null" && (
                                  <div className="ml-2">
                                    <p className="text-xs  capitalize ">
                                      color
                                    </p>
                                    <button
                                      className="rounded-full"
                                      style={{
                                        backgroundColor: `${
                                          item?.newatrribute_1.split("-")[1]
                                        }`,
                                        width: "18px",
                                        height: "18px",
                                        marginLeft: "5px",
                                        marginTop: "3px",
                                      }}
                                    ></button>
                                  </div>
                                )}
                              {item?.newatrribute_1.split("-")[0] !== "color" &&
                                item?.newatrribute_1.split("-")[1] !==
                                  "null" && (
                                  <div className="ml-2 text-xs">
                                    <div className="pl-2">
                                      <p className="text-xs capitalize ">
                                        {item?.newatrribute_1.split("-")[0]}
                                      </p>
                                      <p className=" ">
                                        {item?.newatrribute_1.split("-")[1]}
                                      </p>
                                    </div>
                                  </div>
                                )}
                              {item?.newatrribute_2.split("-")[0] !== "null" &&
                                item?.newatrribute_2.split("-")[1] !==
                                  "null" && (
                                  <div className="pl-2 text-xs">
                                    <p className="text-xs  capitalize ">
                                      {item?.newatrribute_2.split("-")[0]}
                                    </p>
                                    <p className=" ">
                                      {item?.newatrribute_2.split("-")[1]}
                                    </p>
                                  </div>
                                )}
                              {item?.newatrribute_3.split("-")[0] !== "null" &&
                                item?.newatrribute_3.split("-")[1] !==
                                  "null" && (
                                  <div className="pl-2 text-xs">
                                    <p className="text-xs  capitalize ">
                                      {item?.newatrribute_3.split("-")[0]}
                                    </p>
                                    <p className=" ">
                                      {item?.newatrribute_3.split("-")[1]}
                                    </p>
                                  </div>
                                )}
                              {item?.newatrribute_4.split("-")[0] !== "null" &&
                                item?.newatrribute_4.split("-")[1] !==
                                  "null" && (
                                  <div className="pl-2 text-xs">
                                    <p className="text-xs  capitalize ">
                                      {item?.newatrribute_4.split("-")[0]}
                                    </p>
                                    <p className="">
                                      {item?.newatrribute_4.split("-")[1]}
                                    </p>
                                  </div>
                                )}
                              {item?.newatrribute_5.split("-")[0] !== "null" &&
                                item?.newatrribute_5.split("-")[1] !==
                                  "null" && (
                                  <div className="pl-2 text-xs">
                                    <p className="text-xs  capitalize ">
                                      {item?.newatrribute_5.split("-")[0]}
                                    </p>
                                    <p className=" ">
                                      {item?.newatrribute_5.split("-")[1]}
                                    </p>
                                  </div>
                                )}
                            </div>
                            <button
                              onClick={() => handleDeleteProduct(item.id)}
                              type="button"
                              className="flex items-center px-2 py-1 pl-2 space-x-1"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                className="w-4 h-4 fill-current"
                              >
                                <path d="M96,472a23.82,23.82,0,0,0,23.579,24H392.421A23.82,23.82,0,0,0,416,472V152H96Zm32-288H384V464H128Z"></path>
                                <rect
                                  width="32"
                                  height="200"
                                  x="168"
                                  y="216"
                                ></rect>
                                <rect
                                  width="32"
                                  height="200"
                                  x="240"
                                  y="216"
                                ></rect>
                                <rect
                                  width="32"
                                  height="200"
                                  x="312"
                                  y="216"
                                ></rect>
                                <path d="M328,88V40c0-13.458-9.488-24-21.6-24H205.6C193.488,16,184,26.542,184,40V88H64v32H448V88ZM216,48h80V88H216Z"></path>
                              </svg>
                            </button>

                            {/* quantity div */}
                            <div className="pl-3 flex  items-center gap-1 md:gap-3">
                              {parseInt(item.quantity) > 1 && (
                                <button
                                  onClick={() =>
                                    handleDecreaseQuantity(item.id)
                                  }
                                  className={`${themBtn} text-lg  flex items-center justify-center  rounded-full h-5 w-5 p-1 `}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M5 11V13H19V11H5Z"></path>
                                  </svg>
                                </button>
                              )}
                              <p className="text-sm">{item.quantity}</p>
                              <button
                                onClick={() => handleIncreaseQuantity(item.id)}
                                className={` ${themBtn}  text-lg  flex items-center justify-center  rounded-full h-5 w-5  p-0.5`}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z"></path>
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>

                {/* schedule Delivery */}

                {shopDetails?.enable_scheduling && (
                  <div className="flex flex-col md:flex-row gap-2 md:items-center">
                    <p className="text-xs md:text-sm">Schedule Delivery : </p>

                    <div className="flex justify-between items-center md:gap-4">
                      <div
                        className={`${
                          themeTileBorder === "none"
                            ? ``
                            : themeTileBorder === "light"
                            ? ` ${theme}  ${themeTileBorderStyle} shadow-sm p-0.25`
                            : ` ${theme} ${themeTileBorderStyle} shadow-sm p-0.5`
                        } w-32 `}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MobileDatePicker
                            className={`bg-white   ${themeTileBorderStyle} border-none `}
                            onChange={setActivePeriod}
                            value={activePeriod}
                            slotProps={{ textField: { size: "small" } }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </div>

                      {[
                        "Sunday",
                        "Monday",
                        "Tuesday",
                        "Wednesday",
                        "Thursday",
                        "Friday",
                        "Saturday",
                      ].map((item, index) => (
                        <div className="">
                          {activePeriod.day() === index && (
                            <>
                              <div
                                className={`${
                                  themeTileBorder === "none"
                                    ? ``
                                    : themeTileBorder === "light"
                                    ? ` ${theme} ${themeTileBorderStyle} shadow-sm p-0.25`
                                    : ` ${theme} ${themeTileBorderStyle} shadow-sm p-0.5`
                                }  relative`}
                              >
                                <select
                                  className={`bg-white cursor-pointer shadow-md focus:outline-none ${themeTileBorderStyle} text-xs p-2 `}
                                  required
                                  // value={`${dayjs(
                                  //   JSON.parse(selectedSlot).from
                                  // ).format("HH:mm A")}${" - "}${dayjs(
                                  //   JSON.parse(selectedSlot).to
                                  // ).format("HH:mm A")}`}
                                  onChange={(e) => handleSlotSelect(e)}
                                  onClick={() => setSlotErr("")}
                                >
                                  <option value="select">
                                    Select Prefered Time Slot
                                  </option>
                                  {shopDetails.booking_time[item]?.length > 0 &&
                                    shopDetails.booking_time[item].map(
                                      (slot) => (
                                        <option
                                          key={slot.slotNo}
                                          className={` flex gap-2`}
                                          disabled={
                                            (shopDetails.booked_slots.map(
                                              (item) =>
                                                dayjs(
                                                  item.scheduled_time.date
                                                ).format("DD/MM/YYYY") ===
                                                activePeriod.format(
                                                  "DD/MM/YYYY"
                                                )
                                            ) &&
                                              invalidSlot.includes(
                                                slot.slotNo
                                              )) ||
                                            dayjs(activePeriod).isBefore(
                                              dayjs(),
                                              "day"
                                            )
                                          }
                                          value={JSON.stringify(slot)}
                                        >
                                          {dayjs(slot.from).format("HH:mm A")}
                                          {" - "}
                                          {dayjs(slot.to).format("HH:mm A")}
                                        </option>
                                      )
                                    )}
                                </select>
                                {slotErr !== "" && (
                                  <p className="text-red-500 text-xs absolute -bottom-4 right-2">
                                    {slotErr}
                                  </p>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {/* Add note */}
                <div>
                  <button
                    onClick={() => setIsNoteModalOpen(true)}
                    className={`flex items-center text-sm   `}
                  >
                    {customerNote === "" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        className="w-4"
                      >
                        <path d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z"></path>
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        className="w-4"
                      >
                        <path d="M1.18164 12C2.12215 6.87976 6.60812 3 12.0003 3C17.3924 3 21.8784 6.87976 22.8189 12C21.8784 17.1202 17.3924 21 12.0003 21C6.60812 21 2.12215 17.1202 1.18164 12ZM12.0003 17C14.7617 17 17.0003 14.7614 17.0003 12C17.0003 9.23858 14.7617 7 12.0003 7C9.23884 7 7.00026 9.23858 7.00026 12C7.00026 14.7614 9.23884 17 12.0003 17ZM12.0003 15C10.3434 15 9.00026 13.6569 9.00026 12C9.00026 10.3431 10.3434 9 12.0003 9C13.6571 9 15.0003 10.3431 15.0003 12C15.0003 13.6569 13.6571 15 12.0003 15Z"></path>
                      </svg>
                    )}
                    {customerNote !== "" ? "View Note" : "Add Note:"}{" "}
                  </button>
                </div>

                <div className="space-y-1 text-right">
                  <p className="text-sm ">
                    Sub Total: &nbsp;
                    <span className="font-semibold">
                      {isNaN(
                        parseInt(
                          cartData
                            .filter((item) => item.add_ons !== null)
                            .map((item) =>
                              Object.values(
                                item.addon[item.ZaplynTileRef]
                              ).reduce((acc, value) => {
                                return acc + parseInt(value);
                              }, 0)
                            )
                        )
                      )
                        ? totalprice
                        : (
                            parseInt(
                              cartData
                                .filter((item) => item.add_ons !== null)
                                .map((item) =>
                                  Object.values(
                                    item.addon[item.ZaplynTileRef]
                                  ).reduce((acc, value) => {
                                    return acc + parseInt(value);
                                  }, 0)
                                )
                            ) + parseInt(totalprice)
                          ).toFixed(1)}{" "}
                      {shopDetails.currency === "GBP" && (
                        <span className="ml-0.5">£</span>
                      )}
                      {shopDetails.currency === "EUR" && (
                        <span className="ml-0.5">€</span>
                      )}
                      {shopDetails.currency === "USD" && (
                        <span className="ml-0.5">$</span>
                      )}
                    </span>
                  </p>
                  <p className="text-sm text-gray-400">
                    Discount: -
                    {isNaN(
                      parseInt(
                        cartData
                          .filter((item) => item.add_ons !== null)
                          .map((item) =>
                            Object.values(
                              item.addon[item.ZaplynTileRef]
                            ).reduce((acc, value) => {
                              return acc + parseInt(value);
                            }, 0)
                          )
                      )
                    )
                      ? (totalprice * (shopDetails.discount / 100)).toFixed(1)
                      : (
                          (parseInt(
                            cartData
                              .filter((item) => item.add_ons !== null)
                              .map((item) =>
                                Object.values(
                                  item.addon[item.ZaplynTileRef]
                                ).reduce((acc, value) => {
                                  return acc + parseInt(value);
                                }, 0)
                              )
                          ) +
                            parseInt(totalprice)) *
                          (shopDetails.discount / 100)
                        ).toFixed(1)}{" "}
                    {shopDetails.currency === "GBP" && (
                      <span className="ml-0.5">£</span>
                    )}
                    {shopDetails.currency === "EUR" && (
                      <span className="ml-0.5">€</span>
                    )}
                    {shopDetails.currency === "USD" && (
                      <span className="ml-0.5">$</span>
                    )}
                  </p>
                  <p>
                    Total Amount: &nbsp;
                    <span className="font-semibold">
                      {isNaN(
                        parseInt(
                          cartData
                            .filter((item) => item.add_ons !== null)
                            .map((item) =>
                              Object.values(
                                item.addon[item.ZaplynTileRef]
                              ).reduce((acc, value) => {
                                return acc + parseInt(value);
                              }, 0)
                            )
                        )
                      )
                        ? (
                            totalprice -
                            totalprice * (shopDetails.discount / 100)
                          ).toFixed(1)
                        : (
                            parseInt(
                              cartData
                                .filter((item) => item.add_ons !== null)
                                .map((item) =>
                                  Object.values(
                                    item.addon[item.ZaplynTileRef]
                                  ).reduce((acc, value) => {
                                    return acc + parseInt(value);
                                  }, 0)
                                )
                            ) +
                            parseInt(totalprice) -
                            (parseInt(
                              cartData
                                .filter((item) => item.add_ons !== null)
                                .map((item) =>
                                  Object.values(
                                    item.addon[item.ZaplynTileRef]
                                  ).reduce((acc, value) => {
                                    return acc + parseInt(value);
                                  }, 0)
                                )
                            ) +
                              parseInt(totalprice)) *
                              (shopDetails.discount / 100)
                          ).toFixed(1)}
                      {shopDetails.currency === "GBP" && (
                        <span className="ml-0.5">£</span>
                      )}
                      {shopDetails.currency === "EUR" && (
                        <span className="ml-0.5">€</span>
                      )}
                      {shopDetails.currency === "USD" && (
                        <span className="ml-0.5">$</span>
                      )}
                    </span>
                  </p>
                </div>
              </>
            ) : (
              <>
                <p>Your bag is empty</p>
                <p>Looks like you haven't made your choice Yet..</p>
              </>
            )}
            <div>
              {cartData?.length > 0 && shopDetails.partial !== 100 && (
                <p className="flex justify-end text-xs mb-1 md:text-sm">
                  Book now with{" "}
                  {isNaN(
                    parseInt(
                      cartData
                        .filter((item) => item.add_ons !== null)
                        .map((item) =>
                          Object.values(item.addon[item.ZaplynTileRef]).reduce(
                            (acc, value) => {
                              return acc + parseInt(value);
                            },
                            0
                          )
                        )
                    )
                  )
                    ? (
                        (totalprice -
                          totalprice * (shopDetails.discount / 100)) *
                        (shopDetails.partial / 100)
                      ).toFixed(1)
                    : (
                        (parseInt(
                          cartData
                            .filter((item) => item.add_ons !== null)
                            .map((item) =>
                              Object.values(
                                item.addon[item.ZaplynTileRef]
                              ).reduce((acc, value) => {
                                return acc + parseInt(value);
                              }, 0)
                            )
                        ) +
                          parseInt(totalprice) -
                          (parseInt(
                            cartData
                              .filter((item) => item.add_ons !== null)
                              .map((item) =>
                                Object.values(
                                  item.addon[item.ZaplynTileRef]
                                ).reduce((acc, value) => {
                                  return acc + parseInt(value);
                                }, 0)
                              )
                          ) +
                            parseInt(totalprice)) *
                            (shopDetails.discount / 100)) *
                        (shopDetails.partial / 100)
                      ).toFixed(1)}{" "}
                  {shopDetails.currency === "GBP" && (
                    <span className="ml-0.5">£</span>
                  )}
                  {shopDetails.currency === "EUR" && (
                    <span className="ml-0.5">€</span>
                  )}
                  {shopDetails.currency === "USD" && (
                    <span className="ml-0.5">$</span>
                  )}
                  <span className="pl-1">and pay remaining amount later.</span>
                </p>
              )}
              <div className="flex justify-end space-x-4">
                <button
                  onClick={() => backToShop()}
                  type="button"
                  className={`flex justify-center gap-1 w-1/2   ${themBtn}  ${themBtnStyle} px-4 py-1 font-semibold text-lg text-gray-700 shadow-md`}
                >
                  Back
                  <span className="sr-only sm:not-sr-only">to shop</span>
                </button>

                {cartData?.length > 0 && (
                  <button
                    onClick={() => {
                      handlePurchase();
                    }}
                    type="button"
                    className={`flex justify-center gap-1 w-1/2 ${themBtn} ${themBtnStyle} px-4 py-1 font-semibold text-lg text-gray-700 shadow-md`}
                  >
                    <span className="sr-only sm:not-sr-only">Continue to</span>
                    Checkout
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal
        isOpen={isNoteModalOpen}
        className="font-lexand mt-10 max-w-xl z-50 mx-5 sm:mx-auto rounded-md shadow-lg text-center"
        overlayClassName="bg-black z-50 bg-opacity-60 fixed inset-0 overflow-y-auto"
        onRequestClose={() => setIsNoteModalOpen(false)}
      >
        <CustomerNote
          closeOnClick={setNoteModalisOpenToFalse}
          setUserNote={setCustomerNote}
          customerNote={customerNote}
        />
      </Modal>
    </section>
  );
}

export default Cart;
