import React, { useContext, useEffect, useState } from "react";
import "./ProductTile.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { RadioGroup } from "@headlessui/react";
import AttributeComponent from "./AttributeComponent";
import { useQueryClient } from "react-query";
import { convertCurrency } from "../../../helper/helper";
import { ThemeContext } from "../../ThemeContext";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const PreviewModal = ({
  productDetails,
  onCloseClick,
  onAddToCart,
  productCategories,
}) => {
  console.log(productDetails);
  // theme
  const {
    theme,
    setTheme,
    themBtn,
    setThemBtn,
    themBtnStyle,
    setThemBtnStyle,
    themeTileBorder,
    setThemeTileBorder,
    themeTileBorderStyle,
    setThemeTileBorderStyle,
    themeFont,
    setThemeFont,
  } = useContext(ThemeContext);

  const queryClient = useQueryClient();
  const shopDetails = queryClient.getQueryData("shopDetails");
  const [selectedattr1, setSelectedAttr1] = useState("null");
  const [selectedattr2, setSelectedAttr2] = useState("null");
  const [selectedattr3, setSelectedAttr3] = useState("null");
  const [selectedattr4, setSelectedAttr4] = useState("null");
  const [selectedattr5, setSelectedAttr5] = useState("null");
  const [chosenqty, setChosenqty] = useState("1");
  const [qtyOption, setQtyOption] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  //variants
  const [showVariant, setShowVariant] = useState(false);
  const [variants, setVariants] = useState(null);
  const [variantPrice, setVariantPrice] = useState("");
  const [selectedVariant, setSelectedVariant] = useState("null");

  //addons
  const [showAddOn, setShowAddOn] = useState(false);
  const [addOns, setAddOns] = useState(null);
  const [availableAddOn, setAvailableAddOn] = useState(null);
  const [selectedAddOn, setSelectedAddOn] = useState([]);

  // variant price change Onclick()
  const handlePriceChange = (variant) => {
    setSelectedVariant(variant);
    setVariantPrice(parseInt(variants[variant]));
    setTotalPrice(
      parseInt(variants[variant]) +
        (shopDetails.shipping_enabled ? productDetails.shipping_price : 0)
    );
  };

  // Addon change Onclick()
  const handleAddOnChange = (addon) => {
    const newSelected = [...selectedAddOn];

    if (newSelected.includes(addon)) {
      newSelected.splice(newSelected.indexOf(addon), 1);
    } else {
      newSelected.push(addon);
    }
    setSelectedAddOn(newSelected);
  };

  // estimated delivery
  const [selectedEtmDelivery, setSelectedEtmDelivery] = useState("");
  const [fromEtmDelivery, setFromEtmDelivery] = useState("");
  const [toEtmDelivery, setToEtmDelivery] = useState("");

  const [attributeValues, setAttributeValues] = useState(null);

  const [selectedattribute1, setSelectedAttribute1] = useState([]);
  const [selectedattribute2, setSelectedAttribute2] = useState([]);
  const [selectedattribute3, setSelectedAttribute3] = useState([]);
  const [selectedattribute4, setSelectedAttribute4] = useState([]);
  const [selectedattribute5, setSelectedAttribute5] = useState([]);

  const [attribute1, setAttribute1] = useState("null");
  const [attribute2, setAttribute2] = useState("null");
  const [attribute3, setAttribute3] = useState("null");
  const [attribute4, setAttribute4] = useState("null");
  const [attribute5, setAttribute5] = useState("null");

  const randomId = function (length = 6) {
    return Math.random()
      .toString(36)
      .substring(2, length + 2);
  };

  const [attribute1Error, setAttribute1Error] = useState(false);
  const [attribute2Error, setAttribute2Error] = useState(false);
  const [attribute3Error, setAttribute3Error] = useState(false);
  const [attribute4Error, setAttribute4Error] = useState(false);
  const [attribute5Error, setAttribute5Error] = useState(false);

  const handleAddToCartClick = () => {
    window.dataLayer.push({
      shop_id: shopDetails?.ZaplynShopRef,
      shop_name: shopDetails?.shopname,
      product_id: productDetails?.ZaplynTileRef,
      product_name: productDetails?.itemname,
      product_quantity: chosenqty,
      event: "add_to_cart",
    });
    let attr1Err = false;
    let attr2Err = false;
    let attr3Err = false;
    let attr4Err = false;
    let attr5Err = false;
    if (selectedattribute1.length > 0) {
      if (selectedattribute1.length === 1 && selectedattribute1[0] === "") {
        console.log("");
      } else if (selectedattr1 === "null") {
        attr1Err = true;
        setAttribute1Error(true);
      }
    }
    if (selectedattribute2.length > 0) {
      if (selectedattribute2.length === 1 && selectedattribute2[0] === "") {
        console.log("");
      } else if (selectedattr2 === "null") {
        attr2Err = true;
        setAttribute2Error(true);
      }
    }
    if (selectedattribute3.length > 0) {
      if (selectedattribute3.length === 1 && selectedattribute3[0] === "") {
        console.log("");
      } else if (selectedattr3 === "null") {
        attr3Err = true;
        setAttribute3Error(true);
      }
    }
    if (selectedattribute4.length > 0) {
      if (selectedattribute4.length === 1 && selectedattribute4[0] === "") {
        console.log("");
      } else if (selectedattr4 === "null") {
        attr4Err = true;
        setAttribute4Error(true);
      }
    }
    if (selectedattribute5.length > 0) {
      if (selectedattribute5.length === 1 && selectedattribute5[0] === "") {
        console.log("");
      } else if (selectedattr5 === "null") {
        attr5Err = true;
        setAttribute5Error(true);
      }
    }

    if (attr1Err || attr2Err || attr3Err || attr4Err || attr5Err) {
      console.log("Error");
    } else {
      onAddToCart(
        randomId(5),
        productDetails,
        chosenqty,
        `${attribute1}-${selectedattr1.toString()}`,
        `${attribute2}-${selectedattr2.toString()}`,
        `${attribute3}-${selectedattr3.toString()}`,
        `${attribute4}-${selectedattr4.toString()}`,
        `${attribute5}-${selectedattr5.toString()}`,
        Number(totalPrice * parseInt(chosenqty)),
        Number(totalPrice),
        selectedVariant,
        { [productDetails.ZaplynTileRef]: addOns },
        addOns !== null &&
          addOns !== undefined &&
          Object.keys(addOns).length > 0 &&
          Object.values(addOns)
            .map((item) => parseInt(item))
            .reduce((acc, curr) => acc + curr, 0)
      );
      onCloseClick();
    }
  };

  useEffect(() => {
    // console.log("running");
    const divs = [];
    for (let i = 0; i < productDetails.quantity; i++) {
      divs.push(i + 1);
    }
    setQtyOption(divs);
  }, [productDetails.quantity]);

  useEffect(() => {
    setSelectedCategory(productDetails.category);
    setSelectedSubCategory(productDetails.sub_category);
    if (productDetails.attribute_1 !== "") {
      setAttribute1(productDetails.attribute_1.split("-")[0]);
      setSelectedAttribute1(
        productDetails.attribute_1.split("-")[1].split(",")
      );
    }
    if (productDetails.attribute_2 !== "") {
      setAttribute2(productDetails.attribute_2.split("-")[0]);
      setSelectedAttribute2(
        productDetails.attribute_2.split("-")[1].split(",")
      );
    }
    if (productDetails.attribute_3 !== "") {
      setAttribute3(productDetails.attribute_3.split("-")[0]);
      setSelectedAttribute3(
        productDetails.attribute_3.split("-")[1].split(",")
      );
    }
    if (productDetails.attribute_4 !== "") {
      setAttribute4(productDetails.attribute_4.split("-")[0]);
      setSelectedAttribute4(
        productDetails.attribute_4.split("-")[1].split(",")
      );
    }
    if (productDetails.attribute_5 !== "") {
      setAttribute5(productDetails.attribute_5.split("-")[0]);
      setSelectedAttribute5(
        productDetails.attribute_5.split("-")[1].split(",")
      );
    }

    setShowVariant(productDetails?.show_variant);
    setVariants(productDetails?.variant);
    if (productDetails.show_variant === true) {
      setSelectedVariant(Object.keys(productDetails.variant)[0]);
      setVariantPrice(parseInt(Object.values(productDetails?.variant)[0]));
      setTotalPrice(
        parseInt(Object.values(productDetails?.variant)[0]) +
          (shopDetails.shipping_enabled ? productDetails.shipping_price : 0)
      );
    } else {
      setTotalPrice(
        shopDetails.shipping_enabled
          ? productDetails.total_price
          : productDetails.retail_price
      );
    }

    if (productDetails?.add_ons !== null) {
      setShowAddOn(true);
      setAvailableAddOn(productDetails?.add_ons[productDetails?.ZaplynTileRef]);
    }

    const subcategoryObj = productCategories[productDetails.category];
    let attributes;

    if (productDetails.sub_category === "select") {
      attributes = subcategoryObj[productDetails.category];
    } else {
      attributes = subcategoryObj[productDetails.sub_category];
    }
    setAttributeValues(attributes);

    if (productDetails.etm_date !== null) {
      setFromEtmDelivery(productDetails.etm_date?.split(",")[0]);
      setToEtmDelivery(productDetails.etm_date?.split(",")[1]);
      setSelectedEtmDelivery(productDetails.etm_date?.split(",")[2]);
    } else {
      setSelectedEtmDelivery(productDetails.etm_date);
    }
  }, [productDetails]);

  useEffect(() => {
    setAddOns(
      selectedAddOn?.reduce((acc, item) => {
        acc[item] = availableAddOn[item];
        return acc;
      }, {})
    );
  }, [selectedAddOn]);

  // console.log({ [productDetails.ZaplynTileRef]: addOns });

  // console.log("-------------------");
  // console.log("categ", selectedCategory);
  // console.log("sub-categ", selectedSubCategory);
  // console.log("at1", attribute1Error);
  // console.log("at2", attribute2Error);
  // console.log("at3", attribute3Error);
  // console.log("at4", attribute4Error);
  // console.log("at5", attribute5Error);

  // console.log("selectedatrr", selectedattribute1);
  // console.log("selectedatrr", selectedattribute2);
  // console.log("selectedatrr", selectedattribute3);
  // console.log("selectedatrr", selectedattribute4);
  // console.log("selectedatrr", selectedattribute5);
  // console.log("-------------------");

  return (
    <section className={` ${themeFont} `}>
      <div
        className={`bg-white ${themeTileBorderStyle} mx-auto max-w-xl px-4 pt-2 pb-16 sm:px-6 md:grid md:max-w-3xl md:grid-cols-4 md:grid-rows-[auto,auto,1fr] md:gap-x-8 md:px-8 md:pb-24`}
      >
        <div className="md:col-span-2 md:border-r md:border-gray-200 md:pr-8">
          <h1
            className={`text-gray-900 text-2xl font-bold tracking-tight  sm:text-3xl`}
          >
            {productDetails.itemname}
          </h1>
        </div>

        {/* Description and details */}
        <div className=" md:col-span-2 md:col-start-1  md:border-r md:border-gray-200 ">
          <div className="relative p-2 text-5 w-full mb-2 ">
            <Carousel
              infiniteLoop={true}
              showStatus={false}
              showArrows={false}
              swipeable={false}
              thumbWidth={50}
              className="shopProductCarousel"
            >
              {productDetails?.images?.map((image, index) => (
                <div key={index}>
                  <img src={image} alt="" className="" />
                </div>
              ))}
            </Carousel>
            <p className="hidden absolute top-4 left-4 text-gray-300 bg-yellow-100 text-sm font-serif  rounded-md  p-1 ">
              Product Details
            </p>
          </div>
          <h2 className="sr-only">Product information</h2>

          <div className="mt-1">
            <h2 className={`text-gray-900 text-sm font-medium `}>Details</h2>

            <div className="mt-2 space-y-6">
              <p
                className={`text-gray-600
                text-xs `}
              >
                {productDetails.description}
              </p>
            </div>
          </div>
        </div>

        {/* Options */}
        <div className=" md:row-span-3 md:col-span-2 md:mt-0 p-2">
          {/* Price flex-div */}
          <div className="flex justify-between">
            <p
              className={`text-gray-900
              text-2xl tracking-tight `}
            >
              {shopDetails.currency === "GBP" && (
                <span className="mr-1">£</span>
              )}
              {shopDetails.currency === "EUR" && (
                <span className="mr-1">€</span>
              )}
              {shopDetails.currency === "USD" && (
                <span className="mr-1">$</span>
              )}
              {(totalPrice * parseInt(chosenqty)).toFixed(2)}
            </p>
            <div>
              {showVariant ? (
                <p
                  className={` text-gray-500
                   text-xs text-right tracking-tight `}
                >
                  Price: {variantPrice.toFixed(1)}
                  {shopDetails.currency === "GBP" && (
                    <span className="mr-1">£</span>
                  )}
                  {shopDetails.currency === "EUR" && (
                    <span className="mr-1">€</span>
                  )}
                  {shopDetails.currency === "USD" && (
                    <span className="mr-1">$</span>
                  )}
                </p>
              ) : (
                <p
                  className={` text-gray-500
                   text-xs text-right tracking-tight `}
                >
                  Price: {productDetails.retail_price}{" "}
                  {shopDetails.currency === "GBP" && (
                    <span className="mr-1">£</span>
                  )}
                  {shopDetails.currency === "EUR" && (
                    <span className="mr-1">€</span>
                  )}
                  {shopDetails.currency === "USD" && (
                    <span className="mr-1">$</span>
                  )}
                </p>
              )}

              {shopDetails.shipping_enabled &&
                productDetails.shipping_price !== 0 && (
                  <p
                    className={` text-gray-500
                   text-xs text-right tracking-tight `}
                  >
                    Shipping Price: {productDetails.shipping_price.toFixed(1)}{" "}
                    {shopDetails.currency === "GBP" && (
                      <span className="mr-1">£</span>
                    )}
                    {shopDetails.currency === "EUR" && (
                      <span className="mr-1">€</span>
                    )}
                    {shopDetails.currency === "USD" && (
                      <span className="mr-1">$</span>
                    )}
                  </p>
                )}
            </div>
          </div>

          {/* product variants */}
          {showVariant && (
            <div className="flex flex-wrap gap-3 mt-5 items-center ">
              {Object.keys(variants).map((variantName) => (
                <div key={variantName}>
                  <div
                    className={` ${
                      variantName === selectedVariant ? themBtn : "bg-gray-100"
                    }  text-sm p-0.5 ${themBtnStyle} `}
                  >
                    <button
                      onClick={() => handlePriceChange(variantName)}
                      className={`bg-white p-1 px-4 focus:outline-none ${themBtnStyle}`}
                    >
                      {variantName}
                    </button>
                  </div>
                  <p className="text-center text-xs text-gray-500">
                    {shopDetails.currency === "GBP" && (
                      <span className="mr-1">£</span>
                    )}
                    {shopDetails.currency === "EUR" && (
                      <span className="mr-1">€</span>
                    )}
                    {shopDetails.currency === "USD" && (
                      <span className="mr-1">$</span>
                    )}
                    {parseInt(variants[variantName]).toFixed(1)}
                  </p>
                </div>
              ))}
            </div>
          )}

          <form className="mt-5">
            {attributeValues !== null && (
              <div className="">
                {/* ----------**********---------- */}
                {/* attribute-1 */}
                {/* ----------**********---------- */}
                {selectedattribute1.length > 0 && attribute1 === "color" ? (
                  // color
                  <>
                    {selectedattribute1.length > 0 &&
                    selectedattribute1[0] !== "" ? (
                      <div>
                        <h3 className="text-sm font-medium text-left ">
                          Color
                        </h3>
                        {attribute1Error && (
                          <p className="text-red-500 text-xxs">please Select</p>
                        )}

                        <RadioGroup
                          value={selectedattr1}
                          onChange={setSelectedAttr1}
                          className="mt-4 text-xs"
                        >
                          <RadioGroup.Label className="sr-only">
                            {" "}
                            Choose a color{" "}
                          </RadioGroup.Label>
                          <div className="flex items-center space-x-3">
                            {/* {productDetails.color.split(",").map((color) => ( */}

                            {selectedattribute1.map((color) => (
                              <RadioGroup.Option
                                key={color}
                                value={color}
                                className={({ active, checked }) =>
                                  classNames(
                                    color.selectedClass,
                                    active && checked
                                      ? "ring ring-offset-1"
                                      : "",
                                    !active && checked ? "ring-2" : "",
                                    "relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none"
                                  )
                                }
                              >
                                <RadioGroup.Label as="span" className="sr-only">
                                  {" "}
                                  {color}{" "}
                                </RadioGroup.Label>
                                <span
                                  aria-hidden="true"
                                  style={{
                                    backgroundColor: color,
                                  }}
                                  className={classNames(
                                    color,
                                    "h-8 w-8 rounded-full border border-black border-opacity-10"
                                  )}
                                />
                              </RadioGroup.Option>
                            ))}
                          </div>
                        </RadioGroup>
                      </div>
                    ) : (
                      <>
                        {selectedattribute1.length > 1 && (
                          <div>
                            <h3 className="text-sm font-medium text-left">
                              Color
                            </h3>
                            {attribute1Error && (
                              <p className="text-red-500 text-xxs">
                                please Select
                              </p>
                            )}

                            <RadioGroup
                              value={selectedattr1}
                              onChange={setSelectedAttr1}
                              className="mt-4 text-xs"
                            >
                              <RadioGroup.Label className="sr-only">
                                {" "}
                                Choose a color{" "}
                              </RadioGroup.Label>
                              <div className="flex items-center space-x-3">
                                {/* {productDetails.color.split(",").map((color) => ( */}

                                <>
                                  {selectedattribute1.slice(1).map((color) => (
                                    <RadioGroup.Option
                                      key={color}
                                      value={color}
                                      className={({ active, checked }) =>
                                        classNames(
                                          color.selectedClass,
                                          active && checked
                                            ? "ring ring-offset-1"
                                            : "",
                                          !active && checked ? "ring-2" : "",
                                          "relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none"
                                        )
                                      }
                                    >
                                      <RadioGroup.Label
                                        as="span"
                                        className="sr-only"
                                      >
                                        {" "}
                                        {color}{" "}
                                      </RadioGroup.Label>
                                      <span
                                        aria-hidden="true"
                                        style={{
                                          backgroundColor: color,
                                        }}
                                        className={classNames(
                                          color,
                                          "h-8 w-8 rounded-full border border-black border-opacity-10"
                                        )}
                                      />
                                    </RadioGroup.Option>
                                  ))}
                                </>
                              </div>
                            </RadioGroup>
                          </div>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {selectedattribute1.length > 0 && (
                      <>
                        {selectedattribute1.length === 1 &&
                        selectedattribute1[0] === "" ? (
                          <></>
                        ) : (
                          <>
                            <AttributeComponent
                              attributeName={attribute1}
                              attributeErr={attribute1Error}
                              selectedAttribute={selectedattr1}
                              setSelectedAttribute={setSelectedAttr1}
                              attributeValues={attributeValues[attribute1]}
                              availableAttribute={selectedattribute1}
                            />
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
                {/* ----------**********---------- */}
                {/* attribute-2 */}
                {/* ----------**********---------- */}
                {selectedattribute2.length > 0 && (
                  <>
                    {selectedattribute2.length === 1 &&
                    selectedattribute2[0] === "" ? (
                      <></>
                    ) : (
                      <>
                        <AttributeComponent
                          attributeName={attribute2}
                          attributeErr={attribute2Error}
                          selectedAttribute={selectedattr2}
                          setSelectedAttribute={setSelectedAttr2}
                          attributeValues={attributeValues[attribute2]}
                          availableAttribute={selectedattribute2}
                        />
                      </>
                    )}
                  </>
                )}
                {/* ----------**********---------- */}
                {/* attribute-3*/}
                {/* ----------**********---------- */}
                {selectedattribute3.length > 0 && (
                  <>
                    {selectedattribute3.length === 1 &&
                    selectedattribute3[0] === "" ? (
                      <></>
                    ) : (
                      <>
                        <AttributeComponent
                          attributeName={attribute3}
                          attributeErr={attribute3Error}
                          selectedAttribute={selectedattr3}
                          setSelectedAttribute={setSelectedAttr3}
                          attributeValues={attributeValues[attribute3]}
                          availableAttribute={selectedattribute3}
                        />
                      </>
                    )}
                  </>
                )}
                {/* ----------**********---------- */}
                {/* attribute-4 */}
                {/* ----------**********---------- */}
                {selectedattribute4.length > 0 && (
                  <>
                    {selectedattribute4.length === 1 &&
                    selectedattribute4[0] === "" ? (
                      <></>
                    ) : (
                      <>
                        <AttributeComponent
                          attributeName={attribute4}
                          attributeErr={attribute4Error}
                          selectedAttribute={selectedattr4}
                          setSelectedAttribute={setSelectedAttr4}
                          attributeValues={attributeValues[attribute4]}
                          availableAttribute={selectedattribute4}
                        />
                      </>
                    )}
                  </>
                )}
                {/* ----------**********---------- */}
                {/* attribute-5 */}
                {/* ----------**********---------- */}
                {selectedattribute5.length > 0 && (
                  <>
                    {selectedattribute5.length === 1 &&
                    selectedattribute5[0] === "" ? (
                      <></>
                    ) : (
                      <>
                        <AttributeComponent
                          attributeName={attribute5}
                          attributeErr={attribute5Error}
                          selectedAttribute={selectedattr5}
                          setSelectedAttribute={setSelectedAttr5}
                          attributeValues={attributeValues[attribute5]}
                          availableAttribute={selectedattribute5}
                        />
                      </>
                    )}
                  </>
                )}
              </div>
            )}

            {/* quantity */}
            {productDetails.quantity !== 0 ? (
              <>
                <div>
                  <div className="flex mt-4 justify-between">
                    <p className="">Quantity</p>
                  </div>
                  <select
                    name="qty"
                    className={`  ${theme}
                     focus:outline-none  shadow-md text-sm p-2 w-full cursor-pointer ${
                       themBtnStyle === "rounded-full"
                         ? "rounded-xl"
                         : themBtnStyle
                     }`}
                    id="qty"
                    required
                    onChange={(e) => setChosenqty(e.target.value)}
                  >
                    {qtyOption.map((qty) => (
                      <option key={qty} className={``} value={qty}>
                        {qty}
                      </option>
                    ))}
                  </select>
                </div>
              </>
            ) : (
              <p className=" tex-sm mt-4">Currently Unavailable</p>
            )}
          </form>

          {/* Add-ons div */}
          {showAddOn && (
            <div className=" mt-2 ">
              {availableAddOn !== null &&
                availableAddOn !== undefined &&
                Object.keys(availableAddOn).length > 0 &&
                !Object.keys(availableAddOn).some((key) => key === "") && (
                  <>
                    <p className="text-gray-600 text-left">Add-ons -</p>
                    <div className="flex flex-wrap gap-2 mt-1 items-center">
                      {Object.keys(availableAddOn).map((addon) => (
                        <div key={addon}>
                          <div
                            className={` ${
                              selectedAddOn.includes(addon)
                                ? themBtn
                                : "bg-gray-100"
                            }  text-sm p-0.5 ${themBtnStyle} `}
                          >
                            <button
                              onClick={() => handleAddOnChange(addon)}
                              className={`bg-white p-1 px-4  focus:outline-none ${themBtnStyle}`}
                            >
                              {addon}
                            </button>
                          </div>
                          <p className="text-center text-xs text-gray-500">
                            {!isNaN(parseInt(availableAddOn[addon])) && (
                              <span className="mr-1">£</span>
                            )}
                            {isNaN(parseInt(availableAddOn[addon]))
                              ? ""
                              : parseInt(availableAddOn[addon])}
                          </p>
                        </div>
                      ))}
                    </div>
                  </>
                )}
            </div>
          )}

          {selectedEtmDelivery !== null &&
            selectedEtmDelivery !== "" &&
            selectedEtmDelivery !== "select" && (
              <div className=" mt-8">
                <p className="flex gap-2 text-sm items-center justify-end ">
                  Estimated Delivery:{" "}
                  <span className="text-xs ">{fromEtmDelivery}</span>-
                  <span className="text-xs ">{toEtmDelivery}</span>
                  <span className="text-xs ">{selectedEtmDelivery}</span>
                </p>
              </div>
            )}

          {productDetails.quantity !== 0 && (
            <button
              onClick={handleAddToCartClick}
              className={`mt-2 flex w-full items-center justify-center py-3 px-8 text-base font-medium text-gray-700 ${themBtn} ${themBtnStyle}`}
            >
              Add to Cart
            </button>
          )}
        </div>
      </div>
    </section>
  );
};

export default PreviewModal;
